import ApiService from "@/core/services/ApiService";

// 取得IoT裝置排程
export function getDeviceSchedule(deviceGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/DeviceSchedule`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增IoT裝置排程
export function addDeviceSchedule(deviceGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/DeviceSchedule`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 移除IoT裝置排程
export function deleteDeviceSchedule(deviceGuid, deviceScheduleGuid) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Device/${deviceGuid}/DeviceSchedule/${deviceScheduleGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
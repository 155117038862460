import ApiService from "@/core/services/ApiService";

// 取得IoT裝置排程
export function getDeviceScheduleRecord(deviceGuid,deviceScheduleGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/DeviceSchedule/${deviceScheduleGuid}/DeviceScheduleRecord`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
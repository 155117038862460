
import { getTargetDevice } from "@/core/services/api/device";
import { defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  getDeviceSchedule,
  deleteDeviceSchedule,
} from "@/core/services/api/deviceSchedule";
import { getLocalTimeString } from "@/core/utils/timeString";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddIoTSchedule from "./components/AddIoTSchedule.vue";
import IoTScheduleRecordList from "./components/IoTScheduleRecordList.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddIoTSchedule,
    IoTScheduleRecordList
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const deviceGuid = route.params.deviceGuid;
    const device: any = reactive({});
    const scheduleList: any = reactive([]);
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "觸發點位",
        key: "dataPointType",
        sortable: true,
      },
      {
        name: "觸發指令",
        key: "command",
        sortable: true,
      },
      {
        name: "排程表達式",
        key: "cronExpression",
        sortable: true,
      },
      {
        name: "起始時間",
        key: "beginTime",
        sortable: true,
      },
      {
        name: "結束時間",
        key: "endTime",
        sortable: true,
      },
      {
        name: "下次排程時間",
        key: "nextScheduleTime",
        sortable: true,
      },
      {
        name: "優先權",
        key: "priority",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const typeMap = {
      AliveStatus: "存活狀態",
      Power: "電源狀態",
      AirMode: "冷氣模式",
      AirFanSpeed: "冷氣風速",
      AirTemperatureSetting: "冷氣溫度設定",
      AirTemperatureIndoor: "冷氣室內溫度",
      DoorLock: "門鎖",
      BatteryPercentage: "電池",
      LightLevel: "亮度",
      LightColorTemperature: "色溫",
    };
    const enumerationMap = {
      UnKnow: "未知",
      Online: "線上",
      Offline: "離線",
      On: "電源開啟",
      Off: "電源關閉",
      Locked: "上鎖",
      UnLocked: "解鎖",
      AirCondition: "冷氣",
      Dehumidify: "除溼",
      Ventilation: "送風",
      Automatic: "自動",
      Heating: "暖氣",
      Manual: "手動",
    };
    const currentItem: any = reactive([]);
    const addIoTScheduleVisible = ref(false);
    const scheduleRecordVisible = ref(false);

    const setDevice = async () => {
      let request = await getTargetDevice(deviceGuid);
      Object.assign(device, request);
    };

    const setScheduleList = async () => {
      let request = await getDeviceSchedule(deviceGuid);
      scheduleList.splice(0, scheduleList.length, ...request);
    };

    // 根據點位guid 取得data
    const getPointDataByGuid = (guid) => {
      let enumFilter = device.enumDataPoints.filter((o) => o.guid == guid);
      let rangeFilter = device.rangeDataPoints.filter((o) => o.guid == guid);
      if (enumFilter.length > 0) {
        return enumFilter[0];
      } else if (rangeFilter.length > 0) {
        return rangeFilter[0];
      }
    };

    // 根據valueType取得項目指令
    const getCommandByValueType = (valueType, command) => {
      if (valueType == "Enum") {
        return enumerationMap[JSON.parse(command.enumCommand).name];
      } else if (valueType == "Range") {
        return command.rangeCommand;
      }
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of scheduleList) {
        let pointData = getPointDataByGuid(item.dataPoint);
        tableData.push({
          data: item,
          dataPointType: typeMap[pointData.type],
          command: getCommandByValueType(pointData.valueType, item),
          cronExpression: item.cronExpression,
          beginTime: getLocalTimeString(item.beginTime),
          endTime: getLocalTimeString(item.endTime),
          nextScheduleTime: getLocalTimeString(item.nextScheduleTime),
          priority: item.priority,
          createTime: getLocalTimeString(item.createTime),
        });
      }
      console.log("tableData", tableData);
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const onScheduleAdd = async () => {
      addIoTScheduleVisible.value = false;
      await init();
    };

    const deleteSchedule = async () => {
      await Swal.fire({
        title: `您確定要刪除裝置排程嗎?`,
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async () => {
        await deleteDeviceSchedule(deviceGuid, currentItem.data.guid).then(
          async () => {
            await init();
            Swal.fire("刪除成功!", "", "success");
          }
        );
      });
    };

    const init = async () => {
      await setDevice();
      await setScheduleList();
      setTableData();
      console.log("scheduleList", scheduleList);
    };
    init();

    return {
      device,
      tableData,
      tableHeader,
      setCurrentItem,
      addIoTScheduleVisible,
      deviceGuid,
      onScheduleAdd,
      deleteSchedule,
      scheduleRecordVisible,
      currentItem
    };
  },
});


import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { getTargetDevice } from "@/core/services/api/device";
import { addDeviceSchedule } from "@/core/services/api/deviceSchedule";
import { getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    deviceGuid: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      dataPoint: "",
      enumCommand: "",
      rangeCommand: 0,
      cronExpression: "",
      beginTime: "2022-03-28T08:38:07.071Z",
      endTime: "2022-03-28T08:38:07.071Z",
      validityPeriod: [],
      nextScheduleTime: "",
      priority: 0,
    });
    const rules = {
      dataPoint: [
        { required: true, message: "請選擇觸發點位", trigger: "change" },
      ],
      enumCommand: [
        { required: true, message: "請選擇觸發指令", trigger: "change" },
      ],
      cronExpression: [
        { required: true, message: "請填寫排程表達式", trigger: "blur" },
      ],
      validityPeriod: [
        { required: true, message: "請填寫有效期限", trigger: "blur" },
      ],
      nextScheduleTime: [
        { required: true, message: "請填寫下次執行時間", trigger: "blur" },
      ],
    };
    const typeMap = {
      AliveStatus: "存活狀態",
      Power: "電源狀態",
      AirMode: "冷氣模式",
      AirFanSpeed: "冷氣風速",
      AirTemperatureSetting: "冷氣溫度設定",
      AirTemperatureIndoor: "冷氣室內溫度",
      DoorLock: "門鎖",
      BatteryPercentage: "電池",
      LightLevel: "亮度",
      LightColorTemperature: "色溫",
    };
    const enumerationMap = {
      UnKnow: "未知",
      Online: "線上",
      Offline: "離線",
      On: "電源開啟",
      Off: "電源關閉",
      Locked: "上鎖",
      UnLocked: "解鎖",
      AirCondition: "冷氣",
      Dehumidify: "除溼",
      Ventilation: "送風",
      Automatic: "自動",
      Heating: "暖氣",
      Manual: "手動",
    };
    const device: any = reactive({});
    const dataPointOption: any = reactive([]);
    const enumCommandOption: any = reactive([]);

    const onSubmit = () => {
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                dataPoint: formData.dataPoint,
                enumCommand: formData.enumCommand,
                rangeCommand: formData.rangeCommand,
                cronExpression: formData.cronExpression,
                beginTime: getUtcTimeString(formData.validityPeriod[0]),
                endTime: getUtcTimeString(formData.validityPeriod[1]),
                nextScheduleTime: getUtcTimeString(formData.nextScheduleTime),
                priority: formData.priority,
              };
              console.log("request",request)
              await addDeviceSchedule(props.deviceGuid, request).then(
                async () => {
                  await Swal.fire("新增成功!", "", "success");
                  emit("onAdd");
                }
              );
            }
          });
        }
      });
    };

    const setDevice = async () => {
      let request = await getTargetDevice(props.deviceGuid);
      Object.assign(device, request);
    };

    const setDataPointOption = () => {
      let canWriteEnum = device.enumDataPoints.filter((o) =>
        o.permission.includes("Write")
      );
      // 目前不實做Range
      // let canWriteRange = device.rangeDataPoints.filter(o => o.permission.includes("Write"))
      for (const item of canWriteEnum) {
        dataPointOption.push({
          label: typeMap[item.type],
          value: item.guid,
        });
      }
      //   for (const item of canWriteRange) {
      //       dataPointOption.push({
      //           label:typeMap[item.type],
      //           value:item.guid
      //       })
      //   }
    };
    const translateEnumeration = (name) => {
      if (enumerationMap[name] !== undefined) {
        return enumerationMap[name];
      } else {
        return name;
      }
    };

    const setEnumCommandOption = () => {
      formData.enumCommand = "";
      let enumCommandList = device.enumDataPoints.filter(
        (o) => o.guid == formData.dataPoint
      )[0];
      console.log("enumCommandList", enumCommandList);
      enumCommandOption.splice(0);
      for (const item of enumCommandList.enumList) {
        enumCommandOption.push({
          label: translateEnumeration(item.name),
          value: JSON.stringify(item),
        });
      }
    };

    const init = async () => {
      await setDevice();
      setDataPointOption();
    };
    init();

    return {
      ...props,
      dialogShow,
      formData,
      rules,
      formRef,
      onSubmit,
      dataPointOption,
      setEnumCommandOption,
      enumCommandOption,
    };
  },
});


import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import { getDeviceScheduleRecord } from "@/core/services/api/deviceScheduleRecord";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    Dialog,
    Datatable
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    deviceGuid: {
      type: String,
      default: "",
    },
    scheduleGuid: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "guid",
        key: "guid",
        sortable: true,
      },
      {
        name: "排程時間",
        key: "scheduleTime",
        sortable: true,
      },
      {
        name: "執行時間",
        key: "executeTime",
        sortable: true,
      },
      {
        name: "執行結果",
        key: "isSuccess",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
    ]);
    const scheduleRecord: any = reactive([]);

    const setDeviceScheduleRecord = async () => {
      let request = await getDeviceScheduleRecord(
        props.deviceGuid,
        props.scheduleGuid
      );
      scheduleRecord.splice(0, scheduleRecord.length, ...request);
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of scheduleRecord) {
        tableData.push({
          guid: item.guid,
          scheduleTime: getLocalTimeString(item.scheduleTime),
          executeTime: getLocalTimeString(item.executeTime),
          isSuccess: item.isSuccess ? "執行成功" : "執行失敗",
          createTime: getLocalTimeString(item.createTime),
        });
      }
    };

    const init = async () => {
      await setDeviceScheduleRecord();
      console.log("scheduleRecord", scheduleRecord);
      setTableData();
    };
    init();

    return {
      dialogShow,
      tableData,
      tableHeader,
    };
  },
});
